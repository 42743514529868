import { defineStore } from 'pinia';
import { useAuthStore } from './auth';

const tables = ['projets'];

// 10 pastel colors with luminecence > 0.5 and make sur all 10 are different and avoid colors near white and black
const colors = [
  '#F5B7B1',
  '#D2B4DE',
  '#AED6F1',
  '#A2D9CE',
  '#F9E79F',
  '#FADBD8',
  '#D7DBDD',
  '#F5CBA7',
  '#D5F5E3',
  '#F5DA81',
];

const createQueryFilter = (key, values) => {
  const { $_ } = useNuxtApp();
  let qf = {};
  if (values) {
    let keys = key.split('.');
    if (values.includes('label#value:')) {
      const labelsValues = values.replace('label#value:', '').split('|');
      values = labelsValues.map((labelValue) => {
        const [, value] = labelValue.split('#');
        return value;
      });
      const tempQf = keys.reduceRight((acc, key) => ({ [key]: acc }), { $in: values });
      qf = $_.merge({}, qf, tempQf);
    } else if (values.includes('range:')) {
      values = values.replace('range:', '').split('|');
      const tempQf = keys.reduceRight((acc, key) => ({ [key]: acc }), {
        $gte: values[0],
        $lte: values[1],
      });
      qf = $_.merge({}, qf, tempQf);
    } else {
      values = values.split('|');
      const tempQf = keys.reduceRight((acc, key) => ({ [key]: acc }), { $in: values });
      qf = $_.merge({}, qf, tempQf);
    }
  }
  return qf;
};

export const useFiltersStore = defineStore('filters-store', () => {
  const filters = ref(
    tables.reduce((acc, table) => {
      acc[table] = {};
      return acc;
    }, {}),
  );

  watch(
    filters,
    (value) => {
      for (const tableName of Object.keys(value)) {
        const tableState = useDatasTablesState(tableName);
        tableState.value.page = 1;
      }
    },
    { deep: true },
  );

  const columns = ref(
    tables.reduce((acc, table) => {
      acc[table] = [];
      return acc;
    }, {}),
  );

  const defaultColumns = ref(
    tables.reduce((acc, table) => {
      acc[table] = [];
      return acc;
    }, {}),
  );

  const predefinedConfigs = ref([]);

  const authStore = useAuthStore();

  const user = computed(() => authStore.user);

  const currentWorkspaceSavedConfigs = computed(() => {
    if (!user.value?.tables_configs) {
      return [];
    }

    const configs = JSON.parse(user.value.tables_configs) || [];

    return configs.filter((c) => c.wid === useAuthStore().workspace.id);
  });

  const otherWorkspacesSavedConfigs = computed(() => {
    if (!user.value?.tables_configs) {
      return [];
    }

    const configs = JSON.parse(user.value.tables_configs) || [];

    return configs.filter((c) => c.wid !== useAuthStore().workspace.id);
  });

  const userPlusPredefinedConfigs = computed(() => {
    const predefinedConfigsFiltered = predefinedConfigs.value.filter(
      (predefinedConfig) =>
        !currentWorkspaceSavedConfigs.value.some(
          (savedConfig) => predefinedConfig.name === savedConfig.name,
        ),
    );

    return [...predefinedConfigsFiltered, ...currentWorkspaceSavedConfigs.value];
  });

  const isCurrentConfig = computed(() => {
    const { $_ } = useNuxtApp();

    return (config) => {
      return (
        $_.isEqual(filters.value[config.tableName], config.filters) &&
        $_.isEqual(columns.value[config.tableName], config.columns)
      );
    };
  });

  const isUserConfig = computed(() => {
    return (config) => {
      return !!currentWorkspaceSavedConfigs.value.find(
        (c) => JSON.stringify(c) === JSON.stringify(config),
      );
    };
  });

  const currentConfig = computed(() => {
    return (tableName) =>
      currentWorkspaceSavedConfigs.value.find(
        (c) => isCurrentConfig.value(c) && c.tableName === tableName,
      );
  });

  const queryFilters = (tableName) => {
    const { $_ } = useNuxtApp();
    const activeFilters = filters.value[tableName];
    if (!activeFilters) return {};

    const filtersKeys = Object.keys(activeFilters);

    let qf = {};

    filtersKeys.forEach((filterKey) => {
      let values = activeFilters[filterKey];
      if (filterKey.includes('|')) {
        const keys = filterKey.split('|');
        const tempQf = { $or: [] };
        for (let key of keys) {
          tempQf.$or.push(createQueryFilter(key, values));
        }
        qf = $_.merge({}, qf, tempQf);
      } else {
        qf = $_.merge({}, qf, createQueryFilter(filterKey, values));
      }
    });

    return qf;
  };

  function setColumnsVisible(tableName, cols) {
    columns.value[tableName] = cols
      .filter((c) => !c.hidden && c.displayPermission !== false)
      .map((c) => c.label);
  }

  function toggleColumn(tableName, column) {
    const cols = columns.value[tableName];
    const index = cols.indexOf(column);
    if (index >= 0) {
      cols.splice(index, 1);
    } else {
      cols.push(column);
    }
  }

  function setFilter(tableName, filterKey, filterValue) {
    if (!filterValue) {
      delete filters.value[tableName][filterKey];
    } else {
      filters.value[tableName][filterKey] = filterValue;
    }
  }

  function setUserConfigs(configs) {
    user.value.tables_configs = JSON.stringify([...otherWorkspacesSavedConfigs.value, ...configs]);
  }

  function addConfig(name, tableName, isDefault = false, color = null) {
    const configs = currentWorkspaceSavedConfigs.value;

    // fix for default config
    if (isDefault) {
      configs.forEach((c) => {
        if (c.tableName === tableName) {
          c.isDefault = false;
        }
      });
    }

    let existingConfig =
      configs.find((c) => c.name === name && c.tableName === tableName) ||
      predefinedConfigs.value.find((c) => c.name === name && c.tableName === tableName);
    if (existingConfig) {
      const isPredefined = !isUserConfig.value(existingConfig);
      if (isPredefined) {
        const newConfig = { ...existingConfig };
        newConfig.filters = { ...filters.value[tableName] };
        newConfig.columns = [...columns.value[tableName]];
        newConfig.isDefault = isDefault;
        newConfig.wid = useAuthStore().workspace.id;
        configs.unshift(newConfig);
      } else {
        existingConfig.filters = { ...filters.value[tableName] };
        existingConfig.columns = [...columns.value[tableName]];
        existingConfig.isDefault = isDefault;
        existingConfig.wid = useAuthStore().workspace.id;
      }
    } else {
      const lastColor = configs[configs.length - 1]?.color;
      let nextColor = color;
      if (!color) {
        if (lastColor) {
          const lastColorIndex = colors.findIndex((c) => c === lastColor);
          const nextColorIndex = lastColorIndex === colors.length - 1 ? 0 : lastColorIndex + 1;

          nextColor = colors[nextColorIndex];
        } else {
          nextColor = colors[0];
        }
      }

      configs.push({
        name,
        tableName: tableName,
        filters: { ...filters.value[tableName] },
        columns: [...columns.value[tableName]],
        color: nextColor,
        isDefault,
        wid: useAuthStore().workspace.id,
      });
    }
    this.setUserConfigs(configs);

    return configs[configs.length - 1];
  }

  function setPredefinedConfigs(configs) {
    predefinedConfigs.value = configs;
  }

  function removeConfig(config) {
    const configs = currentWorkspaceSavedConfigs.value;
    const configIndex = configs.findIndex(
      (c) => c.name === config.name && c.tableName === config.tableName,
    );
    if (configIndex >= 0) {
      configs.splice(configIndex, 1);
    }
    this.setUserConfigs(configs);
  }

  async function saveUserConfigs() {
    const userLight = { tables_configs: user.value.tables_configs, id: user.value.id };
    await useApi.users.update(userLight);
  }

  function setCurrentConfig(config) {
    if (isCurrentConfig.value(config)) {
      sessionStorage.removeItem('currentConfig');
      resetConfig(config.tableName);
    } else {
      sessionStorage.setItem('currentConfig', JSON.stringify(config));
      setConfig(config.tableName, config);
    }
  }

  function resetConfig(tableName) {
    filters.value[tableName] = {};
    columns.value[tableName] = [...defaultColumns.value[tableName]];
  }

  function setConfig(tableName, config) {
    filters.value[tableName] = { ...config.filters };
    columns.value[tableName] = [...config.columns];
  }

  function setDefaultConfig(tableName, defaultCols) {
    defaultColumns.value[tableName] = [...defaultCols];
    const config = currentWorkspaceSavedConfigs.value.find(
      (c) => c.tableName === tableName && c.isDefault,
    );
    if (config) {
      setConfig(tableName, config);
    } else {
      const localConfig = JSON.parse(sessionStorage.getItem('currentConfig'));
      if (localConfig && localConfig.tableName === tableName) {
        setConfig(tableName, localConfig);
      } else {
        resetConfig(tableName);
      }
    }
  }

  return {
    filters,
    columns,
    defaultColumns,
    predefinedConfigs,
    user,
    currentWorkspaceSavedConfigs,
    userPlusPredefinedConfigs,
    isCurrentConfig,
    isUserConfig,
    currentConfig,
    queryFilters,
    setColumnsVisible,
    toggleColumn,
    setFilter,
    setUserConfigs,
    addConfig,
    setPredefinedConfigs,
    removeConfig,
    saveUserConfigs,
    setCurrentConfig,
    resetConfig,
    setConfig,
    setDefaultConfig,
  };
});
